var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title:
        _vm.model && _vm.item.id
          ? "Detalhes do chamado " + _vm.item.ticket_number
          : "",
      icon: "mdi-buffer",
      show: _vm.dialog,
      maxWidth: "50%",
      actions: _vm.user.is_internal ? true : false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-1" },
              [
                _vm.message
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              color: "warning",
                              prominent: "",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("FormSelectUser", {
                  attrs: {
                    item: _vm.item.author,
                    label: "Autor*",
                    md: "8",
                    outlined: "",
                    "hide-no-data": "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.author_id,
                    disabled: ""
                  },
                  model: {
                    value: _vm.item.author_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "author_id", $$v)
                    },
                    expression: "item.author_id"
                  }
                }),
                _c("FormTicketSelectCategory", {
                  attrs: {
                    item: _vm.item.category,
                    label: "Categoria*",
                    md: "4",
                    outlined: "",
                    "hide-no-data": "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.ticket_category_id,
                    disabled: !_vm.user.is_internal
                  },
                  model: {
                    value: _vm.item.ticket_category_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "ticket_category_id", $$v)
                    },
                    expression: "item.ticket_category_id"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Assunto*",
                    md: "8",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.subject,
                    disabled: ""
                  },
                  model: {
                    value: _vm.item.subject,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "subject", $$v)
                    },
                    expression: "item.subject"
                  }
                }),
                _c("FormTicketSelectType", {
                  attrs: {
                    item: _vm.item.type,
                    md: "4",
                    outlined: "",
                    "hide-no-data": "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.ticket_type_id,
                    disabled: !_vm.user.is_internal
                  },
                  model: {
                    value: _vm.item.ticket_type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "ticket_type_id", $$v)
                    },
                    expression: "item.ticket_type_id"
                  }
                }),
                _c("EmcFormTextArea", {
                  attrs: {
                    label: "Descrição*",
                    md: "12",
                    outlined: "",
                    "auto-grow": "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.description,
                    placeholder: "Informe todos os detalhes do seu chamado...",
                    disabled: ""
                  },
                  model: {
                    value: _vm.item.description,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "description", $$v)
                    },
                    expression: "item.description"
                  }
                }),
                _vm.user.is_internal
                  ? _c("FormSelectUser", {
                      attrs: {
                        item: _vm.item.assigned,
                        label: "Atribuído para",
                        md: _vm.user.is_internal ? 5 : 8,
                        outlined: "",
                        "hide-no-data": "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.assigned_to_id,
                        "is-user-internal": "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.item.assigned_to_id,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "assigned_to_id", $$v)
                        },
                        expression: "item.assigned_to_id"
                      }
                    })
                  : _vm._e(),
                _vm.user.is_internal && _vm.item.id
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.resolution_date,
                            label: "Data Prevista",
                            errorMessages: _vm.errors.resolution_date,
                            show: _vm.dialog,
                            outlined: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "resolution_date",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("FormSelectStatus", {
                  attrs: {
                    item: _vm.item.status,
                    label: "Status*",
                    md: "4",
                    outlined: "",
                    "hide-no-data": "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.ticket_status_id,
                    disabled: !_vm.user.is_internal
                  },
                  model: {
                    value: _vm.item.ticket_status_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "ticket_status_id", $$v)
                    },
                    expression: "item.ticket_status_id"
                  }
                })
              ],
              1
            ),
            _c("v-row", { staticClass: "mt-5 mb-5" }, [_c("v-divider")], 1),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-subtitle-1 transition-swing font-weight-medium"
                    },
                    [_vm._v(" Anexo(s): ")]
                  )
                ]),
                _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _vm._l(_vm.item.attachments, function(file, i) {
                            return _c(
                              "v-col",
                              { key: i, attrs: { cols: "12", sm: "3" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      outlined: "",
                                      color:
                                        _vm.extensions[file.file_type].color,
                                      small: "",
                                      width: "200px",
                                      href:
                                        file.full_url_file +
                                        "?token=" +
                                        _vm.token
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          left: "",
                                          small: "",
                                          color:
                                            _vm.extensions[file.file_type].color
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.extensions[file.file_type].icon
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "d-inline-block text-truncate",
                                        staticStyle: { "max-width": "150px" }
                                      },
                                      [_vm._v(_vm._s(file.file_name))]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _vm.item.attachments == undefined ||
                          _vm.item.attachments.length == 0
                            ? _c("v-col", { attrs: { cols: "12" } }, [
                                _c("span", { staticClass: "text-captian" }, [
                                  _vm._v("Este chamado não possui anexo.")
                                ])
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c("v-row", { staticClass: "mt-5 mb-5" }, [_c("v-divider")], 1),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-subtitle-1 transition-swing font-weight-medium"
                    },
                    [_vm._v(" Cadastrar comentário: ")]
                  )
                ]),
                _c("EmcFormTextArea", {
                  attrs: {
                    label: "Comentário",
                    md: "12",
                    outlined: "",
                    "auto-grow": "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.description,
                    placeholder:
                      "Informe todos os detalhes do seu comentário..."
                  },
                  model: {
                    value: _vm.comment,
                    callback: function($$v) {
                      _vm.comment = $$v
                    },
                    expression: "comment"
                  }
                }),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    _c("EmcFormFile", {
                      attrs: {
                        label: "Anexar no comentário",
                        name: "files",
                        multiple: "",
                        outlined: "",
                        error: _vm.hasValidationErrors.length ? true : false
                      },
                      model: {
                        value: _vm.files,
                        callback: function($$v) {
                          _vm.files = $$v
                        },
                        expression: "files"
                      }
                    })
                  ],
                  1
                ),
                _vm.hasValidationErrors.length
                  ? _c("v-alert", { attrs: { color: "error", outlined: "" } }, [
                      _c("div", { staticClass: "text-subtitle2" }, [
                        _vm._v(" Verifique os arquivos: ")
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.hasValidationErrors, function(
                            item,
                            index
                          ) {
                            return _c("li", { key: index }, [
                              _vm._v(" " + _vm._s(item[1][0]) + " ")
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.user.is_internal
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Comentário vísivel apenas para usuários internos do SysClass",
                            label: "Comentário Interno"
                          },
                          model: {
                            value: _vm.isInternal,
                            callback: function($$v) {
                              _vm.isInternal = $$v
                            },
                            expression: "isInternal"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: _vm.user.is_internal ? 6 : 12 } },
                  [
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-2 white--text",
                            attrs: {
                              color: "primary",
                              small: "",
                              loading: _vm.savingComments,
                              disabled: _vm.savingComments,
                              right: ""
                            },
                            on: { click: _vm.saveComment }
                          },
                          [
                            _vm._v(" Salvar comentário "),
                            _c(
                              "v-icon",
                              { attrs: { right: "", dark: "", small: "" } },
                              [_vm._v(" mdi-content-save ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c("v-row", { staticClass: "mt-5 mb-5" }, [_c("v-divider")], 1),
            _c(
              "v-row",
              { staticClass: "mt-1" },
              [
                _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-subtitle-1 transition-swing font-weight-medium"
                    },
                    [_vm._v(" Comentários: ")]
                  )
                ]),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    _vm.commentsReverse.length
                      ? _c(
                          "v-card",
                          { staticClass: "elevation-0" },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-timeline",
                                  { attrs: { "align-top": "", dense: "" } },
                                  _vm._l(_vm.commentsReverse, function(
                                    comment,
                                    i
                                  ) {
                                    return _c(
                                      "v-timeline-item",
                                      {
                                        key: i,
                                        attrs: { color: "grey", small: "" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "pt-1" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(comment.created_at)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              [
                                                _vm.user.id ==
                                                  comment.user_id ||
                                                _vm.user.is_internal
                                                  ? _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          comment.user.name
                                                        )
                                                      )
                                                    ])
                                                  : _c("strong", [
                                                      _vm._v("Suporte SysClass")
                                                    ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          comment.description
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(
                                                  comment.attachments,
                                                  function(file, i) {
                                                    return _c(
                                                      "v-btn",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          icon: "",
                                                          href:
                                                            file.full_url_file +
                                                            "?token=" +
                                                            _vm.token
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                _vm.extensions[
                                                                  file.file_type
                                                                ].color
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.extensions[
                                                                  file.file_type
                                                                ].icon
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                comment.is_internal == 1
                                                  ? _c("small", [
                                                      _vm._v(
                                                        "[ Comentário Interno ]"
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", { staticClass: "text-captian" }, [
                            _vm._v("Este chamado não possui comentário.")
                          ])
                        ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }